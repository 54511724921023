<template>
  <div v-if="isMobile" class="successBoxPhone">
    <div class="banner">
      <img v-if="banner !==''" :src="constUrl+banner">
    </div>
    <div class="successContent" v-for="(item,index) in dataList" :key="item.id">
      <div class="leftPic" v-if="index % 2 == 0">
        <div class="leftBox">
          <img :src="constUrl+item.picture">
        </div>
        <div class="text">
          <h4> {{item.name}}</h4>
          <p>{{item.detail}}
            <span @click="toCase(item.id)">查看详情 >></span>
          </p>
        </div>
      </div>
      <div class="rightPic" v-if="index % 2 !== 0">
        <div class="text">
          <h4> {{item.name}}</h4>
          <p>{{item.detail}}
            <span @click="toCase(item.id)">查看详情 >></span>
          </p>
        </div>
        <div class="rightBox">
          <img :src="constUrl+item.picture">
        </div>
      </div>
    </div>
    <el-button @click="loadMore" v-if="isShow==1" :loading="isLoading">加载更多...</el-button>
    <el-button v-else-if="isShow==2">加载完成</el-button>
    <footer-box v-if="isMobile"   class="footerBoxAll" style="z-index: 999" ></footer-box>
  </div>
  <div v-else class="successBox">
    <div class="banner">
      <img v-if="banner !==''" :src="constUrl+banner">
    </div>
    <div class="successContent" v-for="(item,index) in dataList" :key="item.id">
      <div class="leftPic" v-if="index % 2 == 0">
        <div class="leftBox">
          <img :src="constUrl+item.picture">
        </div>
        <div class="text">
          <h4> {{item.name}}</h4>
          <p>{{item.detail}}
            <span @click="toCase(item.id)">查看详情 >></span>
          </p>
        </div>
      </div>
      <div class="rightPic" v-if="index % 2 !== 0">
        <div class="text">
          <h4> {{item.name}}</h4>
          <p>{{item.detail}}
            <span @click="toCase(item.id)">查看详情 >></span>
          </p>
        </div>
        <div class="rightBox">
          <img :src="constUrl+item.picture">
        </div>
      </div>
    </div>
    <el-button @click="loadMore" v-if="isShow==1" :loading="isLoading">加载更多...</el-button>
    <el-button v-else-if="isShow==2">加载完成</el-button>
  </div>
</template>

<script>
  import ItemMixins from "../item.mixins";

  export default {
    mixins: [ItemMixins],
    data() {
      return {
        isLoading: false,
        isShow: 1,
        banner: '',
        dataList: [],
        form: {
          pageSize: 2,
          page: 1,
        }
      }
    },
    methods: {
      //加载跟多
      loadMore() {
        this.form.page += 1
        this.loadData()
      },
      toCase(id) {
        this.$router.push({
          path: `/case/detail/${id}`,
        })
      },
      async loadData() {
        this.isLoading = true
        let list = await this.getChildList(this.form.page, this.form.pageSize)
        if (list.length == 0) {
          this.isShow = 2
        } else {
          this.isShow = 1
        }
        list.map((x) => {
          this.dataList.push(x)
        })
        this.isLoading = false
      },
      //解决从首页跳转过来的问题
      async chilidList() {
        let res = await this.getHeadMenuList()
        this.banner = res[3].picture[0]
        this.setActiveItem(res[3])
        this.loadData()

      },
      //获取数据
      async init() {
        await this.getHeadMenuList()
        let item = this.getActiveItem()
        this.banner = item.picture[0]
        this.loadData()
      },
    },
    mounted() {
      if (this.$route.query.forms) {
        this.chilidList()
        return
      }
      // 进行判断是否是第一次进入页面，如果是第一次进入页面就调取接口请求，同时并生成sessionStorage,当前页面不是第一次进入
      this.init()

    }
  }
</script>

<style scoped lang="less">
  .successBox {
    width: 100vw;
    padding-bottom: 6vh;

    .el-button {
      margin-left: 50vw;
      background-image: linear-gradient(#43d5fd, #0385ff);
      color: white;
      font-size: 20px;
      font-weight: 600;
      /*line-height: 50px;*/
      height: 50px;
    }

    .banner {
      width: 100%;
      height: 60vh;
      overflow: hidden;
      margin: auto;

      img {
        width: 100%;
        /*height: 100%;*/
      }
    }

    .successContent {
      width: 70%;
      height: 100%;
      margin: auto;
      padding-top: 60px;

      h4 {
        font-size: 1.3rem;
        width: 90%;
        color: transparent;
        margin: auto;
        background-image: linear-gradient(#43d5fd, #0385ff);
        -webkit-background-clip: text;
        font-weight: 700;
      }

      .text {
        width: 50%;

        p {
          line-height: 180%;
          width: 90%;
          color: #555555;
          text-indent: 5%;
          margin: auto;
          padding-top: 20px;

          span {
            cursor: pointer;
            margin-left: 15%;
            color: #43d5fd;
            font-weight: 600;
          }
        }

      }

      .leftPic {
        width: 100%;
        height: 40vh;
        border: 1px solid #e4e4e4;
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .leftBox {
          width: 50%;
          height: 100%;

          img {
            width: 100%;
            height: 92%;
            margin-top: 2%;
            margin-left: -4%;
            border-radius: 10px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
          }
        }

        .leftBox2 {
          width: 50%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .leftBox2_box {
            width: 96%;
            height: 94%;
            box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.6);
            background-size: cover;
          }

        }
      }

      .rightPic {
        width: 100%;
        height: 40vh;
        border: 1px solid #e4e4e4;
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .rightBox {
          width: 50%;
          height: 100%;

          img {
            width: 100%;
            height: 92%;
            margin-top: 2%;
            margin-left: 4%;
            border-radius: 10px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
          }
        }
      }
    }
  }
</style>

<!--手机款样式-->
<style scoped lang="less">
  .successBoxPhone {
    width: 100vw;
    padding-bottom: 6vh;
    position: relative;
    left: 0;
    top: 0;


    .el-button {
      margin-left: 30%;
      background-image: linear-gradient(#43d5fd, #0385ff);
      color: white;
      font-size: 20px;
      font-weight: 600;
      /*line-height: 50px;*/
      height: 50px;
    }

    .banner {
      width: 100%;
      overflow: hidden;
      margin: auto;
      height: 100%;

      img {
        width: 100%;
        /*height: 100%;*/
      }
    }

    .successContent {
      width: 90%;
      height: 100%;
      margin: auto;
      margin-bottom: 5vw;
      box-sizing: border-box;

      h4 {
        font-size: 1.3em;
        width: 90%;
        color: transparent;
        margin: auto;
        background-image: linear-gradient(#43d5fd, #0385ff);
        -webkit-background-clip: text;
        font-weight: 700;
      }

      .text {
        width: 50%;

        p {
          line-height: 110%;
          width: 90%;
          color: #555555;
          text-indent: 5%;
          font-size: 2vw;
          margin: auto;
          padding-top: 2vw;

          span {
            cursor: pointer;
            color: #43d5fd;
            display: block;
            font-weight: 600;
          }
        }

      }

      .leftPic {
        width: 100%;
        height: 100%;
        border: 1px solid #e4e4e4;      padding: 4vw 0;


        /*margin-bottom: 40px;*/
        display: flex;
        align-items: center;
        justify-content: space-between;

        .leftBox {
          width: 50%;
          height: 100%;

          img {
            width: 100%;
            height: 92%;
            margin-top: 2%;
            margin-left: -4%;
            border-radius: 10px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
          }
        }

        .leftBox2 {
          width: 50%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .leftBox2_box {
            width: 96%;
            height: 94%;
            box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.6);
            background-size: cover;
          }

        }
      }

      .rightPic {
        width: 100%;
        height: 100%;
        border: 1px solid #e4e4e4;
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4vw 0;

        .rightBox {
          width: 50%;
          height: 100%;

          img {
            width: 100%;
            height: 92%;
            margin-top: 2%;
            margin-left: 4%;
            border-radius: 10px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
          }
        }
      }
    }
  }
</style>
